import { addEventDelegate } from "../premmio/public/modules/eventDelegate/eventDelegate.js";
import { xhrFormRecaptcha } from "../premmio/public/modules/xhr/xhr.js";
import { parallax } from "../premmio/public/modules/parallax/parallax.js";

parallax(
  ".sectionTransition, section:nth-of-type(1):not(#splash) article, .feature"
);

const submitContactForm = (form) => {
  xhrFormRecaptcha({ form });
};

addEventDelegate("submit", "#contact", submitContactForm, true);

const setScrolledValue = (scrollPos) => {
  const header = document.querySelector("header");

  if (scrollPos > 250) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
};

addEventDelegate("scroll", window, setScrolledValue);
